<template>
    <div class="container">
        <!-- <div class="commonSearch">
            <div class="commonInputGroup">
                <label class="title">错误码：</label>
                <div class="content">
                    <label v-for="(item,index) in errorCodeList" :key="index">
                        <input  v-model="errorCode" type="radio" :value="item.value" />{{item.name}}
                    </label> 
                </div>
            </div>

            <div class="commonInputGroup">
                <label class="title">状态：</label>
                <div class="content">
                    <label><input v-model="isProcessed" type="radio" value="1" />已处理</label> 
                    <label><input v-model="isProcessed" type="radio" value="0" />未处理</label> 
                </div>
            </div>

        </div> -->

        <div class="commonList" v-show="dataList.length>0">
            <table>
                <tbody>
                    <tr v-for="(item,index) in dataList" :key="index">
                        <td>
                            <div>
                                <span class="exceptionId">#{{item.id}}</span>
                                <span class="exceptionTime">发生时间：{{item.create_time}}</span>
                                <el-button type="primary" size="small" @click="updateProcessedStatus(item)" round plain>置为已处理</el-button></div>
                            <pre style="font-weight:bold;">{{item.title}}</pre>
                            <pre>{{item.content}}</pre>
                            <pre>{{proJson(item.server_info)}}</pre>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="commonLoading" v-show="isLoading">正在加载...</div>
        <div class="commonNotData" v-show="isNotData">没有数据！</div>

    </div>
</template>

<script>

import { exceptionListPost,updateProcessedStatusPost } from '@/service/api'

export default {
    data(){
        return {
            errorCode:0,
            errorCodeList:[
                {"name":"所有",value:"0"},
                {"name":"500",value:"500"},
                {"name":"404",value:"404"}
            ],
            isProcessed:0,
            isLoading:false,
            isNotData:false,
            dataList:[],
            pageSize:100,
            currentPage:1
        }
    },
    mounted(){
        this.loadData();
    },
    methods:{
        loadData(){
            var params = {"currentPage":this.currentPage,"pageSize":this.pageSize};

            this.isLoading = true;
            exceptionListPost(params).then(res => {
                if(res.status_code == 0){
                    this.dataList = res.data;
                    this.isNotData = this.dataList.length<=0?true:false;
                }
            }).catch(err => {
                err;
                this.$message.error('发生错误，如果响应数据量过大，会导至请求失败，请检查数据量！');
            }).finally(()=>{
               this.isLoading = false;
            });
        },
        updateProcessedStatus(item){

             this.$confirm('确认已处理？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var params = {"id":item.id,"status":"1"};
                updateProcessedStatusPost(params).then(res => {
                    if(res.status_code == 0){
                        this.loadData();
                    }
                }).finally(()=>{

                });
            });
        },
        proJson(data){
            return eval('('+data +')');
        }
    }

}
</script>

<style scoped>

.commonList{
    overflow-y: auto;
    height:100%;
}
.commonList td{
    text-align: left;
    padding: 10px;
    padding-top: 20px;
}

.exceptionId{
    font-weight: bold;
    margin-right: 20px;
    color: red;
}
.exceptionTime{
    margin-right: 20px;
}

</style>